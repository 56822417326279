import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import pdf from "../../../assets/CV_Muhammad-Syauqi-Al-Bashir.pdf";

const About = () => {
  return (
    <div>
      <Container>
        {/* <Slide left > */}
        <Row className="mt-5">
          <Col md={5}>
            {/* <GreetingLottie animationPath="/lottie/coding.json" /> */}

            <Slide left>
              <lottie-player
                src="https://assets5.lottiefiles.com/packages/lf20_iv4dsx3q.json"
                background="transparent"
                speed="1"
                style={{ width: "100%", height: "90%" }}
                loop
                autoplay
              ></lottie-player>
            </Slide>
          </Col>

          <Col md={7}>
            <Fade duration={3000}>
              <p className="home-about-body">
                My name is <b className="purple">Muhammad Syauqi Al Bashir</b>
                and I am a{" "}
                <b className="purple">Full Stack Software Developer </b>
                Graduated from <b className="purple">Hactiv8 </b>
                Full Stack Java Script Program. I am passionate about technology
                and I love to learn new, enhanced, advanced technology.
                <br />
                <br />I am proficient in various programming languages such as
                &nbsp;
                <b className="purple">
                  {" "}
                  JavaScript, HTML, CSS, and TypeScript.{" "}
                </b>
                <br />
                <br />I am a problem-solving enthusiast with a passion for
                programming and high adaptability skill, especially in{" "}
                <b className="purple">
                  web development and mobile applications.{" "}
                </b>
                <br />
                <br />
                Began my programming journey by finishing{" "}
                <b className="purple">
                  {" "}
                  Hacktiv8 bootcamp as a full-stack web developer{" "}
                </b>{" "}
                and graduated earning an{" "}
                <b className="purple"> honor award with the highest score </b> ,
                having decided to leave my previous career in the social worker
                and administration industry to pursue what has been a lifelong
                passion, technology with problem solving oriented.
                <br />
                <br />
                You can  <b className="purple"> Download My Resume </b> Here :
              </p>

              <Button variant="primary" href={pdf} target="_blank">
                  <AiOutlineDownload />
                  &nbsp;Download Resume
                </Button>
            </Fade>
          </Col>
        </Row>
        {/* </Slide> */}
      </Container>
    </div>
  );
};

export default About;
